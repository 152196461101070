class Errors {
    constructor() {
        this.errors = {};
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    get(field) {
        return this.errors[field] ? this.errors[field][0] : null;
    }

    exists(field) {
        return !!this.errors[field];
    }

    record(errors) {
        this.errors = errors;
    }

    clear(field) {
        delete this.errors[field];
    }

    clearAll() {
        this.errors = {};
    }
}

export default Errors;