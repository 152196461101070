<template>
    <div class="snackbar">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Snackbar"
    };
</script>

<style scoped>
    .snackbar {
        visibility: hidden;
        width: auto;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 16px;
        position: fixed;
        z-index: 1;
        right: 2rem;
        bottom: 2rem;
        font-size: 17px;
    }

    .snackbar.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 2rem;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 2rem;
            opacity: 1;
        }
    }

    /*@-webkit-keyframes fadeout {*/
    /*    from {*/
    /*        bottom: 2rem;*/
    /*        opacity: 1;*/
    /*    }*/
    /*    to {*/
    /*        bottom: 0;*/
    /*        opacity: 0;*/
    /*    }*/
    /*}*/

    /*@keyframes fadeout {*/
    /*    from {*/
    /*        bottom: 2rem;*/
    /*        opacity: 1;*/
    /*    }*/
    /*    to {*/
    /*        bottom: 0;*/
    /*        opacity: 0;*/
    /*    }*/
    /*}*/
</style>