<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <CModal :visible="visible" @close="close" backdrop="static">
        <CModalHeader>
            <CModalTitle>{{ modalTitle }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CFormInput :feedback-invalid="form.errors.get('username')" :invalid="form.errors.exists('username')"
                        @input="form.errors.clear('username')" class="mb-2" label="Username" placeholder="Username"
                        size="sm" type="text" v-model="form.username"/>
            <CFormInput :feedback-invalid="form.errors.get('email')" :invalid="form.errors.exists('email')"
                        @input="form.errors.clear('email')" class="mb-2" label="Email" placeholder="Email"
                        size="sm" type="Email" v-model="form.email"/>
            <CFormInput :feedback-invalid="form.errors.get('password')" :invalid="form.errors.exists('password')"
                        @input="form.errors.clear('password')" class="mb-2" label="Password" placeholder="Password"
                        size="sm" type="Password" v-model="form.password"/>
            <CFormInput :feedback-invalid="form.errors.get('password_confirmation')" :invalid="form.errors.exists('password_confirmation')"
                        @input="form.errors.clear('password_confirmation')" class="mb-2" label="Confirm Password" placeholder="Confirm password"
                        size="sm" type="Password" v-model="form.password_confirmation"/>
        </CModalBody>
        <CModalFooter>
            <CButton @click="removePrompt" class="me-auto" color="danger" v-if="user" variant="outline">
                {{ $t('placeholders.delete') }}
            </CButton>
            <CButton @click="close" color="secondary">{{ $t('placeholders.cancel') }}</CButton>
            <CButton :disabled="form.errors.any()" @click="save" color="primary">{{ $t('placeholders.save') }}</CButton>
        </CModalFooter>
        <div class="d-flex justify-content-center align-items-center loading-overlay" ref="modelOverlay">
            <CSpinner color="info" size="sm"/>
        </div>
    </CModal>
</template>

<script>
    import {axios, miscellaneous} from './../../mixins';
    import {Errors} from './../../models';
    import useConfirmBeforeAction from "./../../utils/useConfirmBeforeAction";

    export default {
        name: "UserModal",
        mixins: [axios, miscellaneous],
        props: {
            endpoint: {type: String, required: true},
            show: {type: Boolean, required: true},
            title: {type: String, default: null},
            user: {type: Object, default: null},
            closeOnBackdrop: {type: Boolean, default: false}
        },
        computed: {
            modalTitle() {
                return this.title ? this.title : this.$t('placeholders.user');
            }
        },
        watch: {
            show(newValue) {
                this.visible = newValue;
            },
            user(newValue) {
                this.resetForm();
                if (newValue) {
                    this.form.username = newValue.username;
                    this.form.email = newValue.email;
                    this.form.password = newValue.password;
                    this.form.password_confirmation = newValue.password_confirmation;
                }

            },
            loading(newValue) {
                this.$refs.modelOverlay.style.zIndex = newValue ? 1 : -1;
            }
        },
        data() {
            return {
                visible: this.show,
                loading: false,
                form: {
                    errors: new Errors(),
                    username: null,
                    email: null,
                    password: null,
                    password_confirmation: null
                }
            };
        },
        methods: {
            removePrompt() {
                document.activeElement.blur();
                useConfirmBeforeAction(async () => {
                    await this.remove();
                }, {question: this.$t('placeholders.confirm_delete_message')});
            },
            async remove() {
                this.loading = true;
                const {status, statusText, data: response} = await this._api().delete(`${this.endpoint}/${this.user.uuid}`);
                switch (status) {
                    case 200:
                        this.close({event: 'removed', data: response});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.remove();
                        } else {
                            this.$router.push({name: 'Logout', params: {locale: this.$i18n.locale}});
                        }
                        break;
                    case 404:
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
                this.loading = false;
            },
            async save() {
                document.activeElement.blur();
                this.loading = true;
                const form = this.form;
                let apiResponse = {};
                if (this.user) {
                    apiResponse = await this._api().put(`${this.endpoint}/${this.user.uuid}`, this.removeEmptyAttributes(form));
                } else {
                    apiResponse = await this._api().post(this.endpoint, this.removeEmptyAttributes(form));
                }
                const {status, statusText, data: response} = apiResponse;
                switch (status) {
                    case 200:
                        this.close({event: this.user ? 'updated' : 'created', data: response});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.save();
                        } else {
                            this.$router.push({name: 'Logout', params: {locale: this.$i18n.locale}});
                        }
                        break;
                    case 422:
                        this.form.errors.record(response);
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
                this.loading = false;
            },
            close(obj) {
                let event = null;
                let data = null;
                if (obj) {
                    event = obj.event ? obj.event : null;
                    data = obj.data ? obj.data : null;
                }
                this.$emit('closed', {name: this.$options.name, event, data});
                this.resetForm();
            },
            resetForm() {
                this.form.username = null;
                this.form.email = null;
                this.form.password = null;
                this.form.password_confirmation = null;
                this.form.errors.clearAll();
            }
        }
    };
</script>

<style scoped>
    :deep(.ck-editor) {
        min-height: 120px;
        max-height: 360px;
        overflow: scroll;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0.7;
        z-index: -1;
        border: 3px solid transparent;
    }
</style>