<!--suppress HtmlUnknownTag, CheckEmptyScriptTag, HtmlUnknownAttribute, ES6CheckImport, CssInvalidPseudoSelector -->
<template>
    <div class="datatable">
        <easy-data-table :empty-message="$t('placeholders.no_data_available')" :headers="headers" :items="items"
                         :loading="loading" :rows-items="rowsItems" :server-items-length="serverItemsLength"
                         :server-options="serverOptions" @update:server-options="loadData" alternating
                         buttons-pagination show-index theme-color="var(--cui-primary)">
            <template #item-is_active="{uuid, is_active}">
                <div class="d-flex justify-content-center align-items-center">
                    <CBadge :color="is_active ? 'primary' : 'danger'" @click="toggle(uuid)" class="cursor-pointer"
                            shape="rounded-pill" v-if="actionToggle">
                        {{ is_active ? 'Active' : 'Inactive' }}
                    </CBadge>
                </div>
            </template>
            <template #item-is_admin="{is_admin}">
                <div class="d-flex justify-content-center align-items-center">
                    <CBadge :color="is_admin ? 'info' : 'primary'" shape="rounded-pill">
                        {{ is_admin ? 'Admin' : 'User' }}
                    </CBadge>
                </div>
            </template>
            <template #item-action="{uuid}">
                <div class="action-column" v-if="actionShow || actionEdit || actionDelete">
                    <CButtonGroup aria-label="Basic example" role="group">
                        <CButton @click="show(uuid)" class="text-white" color="info" size="sm" v-if="actionShow">
                            <CIcon name="cil-folder-open"/>
                        </CButton>
                        <CButton @click="edit(uuid)" class="text-white" color="secondary" size="sm" v-if="actionEdit">
                            <CIcon name="cil-pencil"/>
                        </CButton>
                        <CButton @click="confirmRemove(uuid)" class="text-white" color="danger" size="sm" v-if="actionDelete">
                            <CIcon name="cil-trash"/>
                        </CButton>
                    </CButtonGroup>
                </div>
            </template>
            <template #expand="item">
                <slot name="expand" v-bind="item"></slot>
            </template>
        </easy-data-table>
    </div>
</template>

<script>
    import Vue3EasyDataTable from 'vue3-easy-data-table';
    import {filter, map} from 'lodash';
    import {axios, miscellaneous} from './../mixins';
    import useConfirmBeforeAction from "./../utils/useConfirmBeforeAction";

    export default {
        name: "Datatable",
        mixins: [axios, miscellaneous],
        components: {
            EasyDataTable: Vue3EasyDataTable
        },
        props: {
            endpoint: {type: String, required: true},
            columns: {type: Array, required: true},
            actionDelete: {type: Boolean, default: false},
            actionEdit: {type: Boolean, default: false},
            actionToggle: {type: Boolean, default: false},
            actionShow: {type: Boolean, default: false}
        },
        data: () => {
            return {
                items: [],
                loading: false,
                serverItemsLength: 0,
                rowsItems: [5, 10, 15, 25, 50, 100],
                serverOptions: {
                    page: 1,
                    rowsPerPage: 10,
                    sortBy: null,
                    sortType: null
                }
            };
        },
        computed: {
            headers() {
                return this.columns.map(column => {
                    return {
                        text: column.label,
                        value: column.value,
                        width: column.width,
                        sortable: column.sortable,
                        fixed: column.fixed
                    };
                });
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            show(uuid) {
                document.activeElement.blur();
                this.$emit('action', {type: 'show', data: {uuid: uuid}});
            },
            edit(uuid) {
                document.activeElement.blur();
                this.$emit('action', {type: 'edit', data: {uuid: uuid}});
            },
            append(item) {
                this.items.push(item);
            },
            prepend(item) {
                this.items = this.prependValueToArray(item, this.items);
            },
            async toggle(uuid) {
                document.activeElement.blur();
                this.loading = true;
                const {status, statusText, data: response} = await this._api().patch(`${this.endpoint}/${uuid}/toggle`);
                switch (status) {
                    case 200:
                        await map(this.items, item => {
                            if (item.uuid === response.uuid)
                                item.is_active = response.is_active;
                        });
                        this.loading = false;
                        this.$emit('action', {type: 'toggle', data: {uuid: uuid}});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.toggle(uuid);
                        } else {
                            this.$emit('error', {type: 'refresh', data: {title: status, message: statusText}});
                        }
                        break;
                }
            },
            confirmRemove(uuid) {
                document.activeElement.blur();
                useConfirmBeforeAction(async () => {
                    await this.remove(uuid);
                }, {question: this.$t('placeholders.confirm_delete_message')});
            },
            async remove(uuid) {
                this.loading = true;
                const {status, statusText, data: response} = await this._api().delete(`${this.endpoint}/${uuid}`);
                switch (status) {
                    case 200:
                        if (response.deleted) {
                            this.items = await filter(this.items, item => item.uuid !== response.uuid);
                            this.loading = false;
                            this.$emit('action', {type: 'delete', data: {uuid: uuid}});
                        }
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.remove(uuid);
                        } else {
                            this.$emit('error', {type: 'refresh', data: {title: status, message: statusText}});
                        }
                        break;
                    case 404:
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
            },
            async loadData(serverOptions) {
                if (serverOptions) {
                    this.serverOptions = serverOptions;
                }
                this.loading = true;
                const {status, statusText, data: response} = await this._api().get(this.endpoint, {
                    params: {
                        length: this.serverOptions.rowsPerPage,
                        page: this.serverOptions.page
                    }
                });
                switch (status) {
                    case 200:
                        this.items = response.data;
                        this.serverItemsLength = response.total;
                        this.loading = false;
                        this.$emit('loaded', response);
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.loadData();
                        } else {
                            this.$emit('error', {type: 'refresh', data: {title: status, message: statusText}});
                        }
                        break;
                    case 403:
                        this.$emit('error', {type: 'forbidden', data: {title: status, message: statusText}});
                        break;
                }
            }
        }
    };
</script>

<style scoped>
    @import '~vue3-easy-data-table/dist/style.css';

    .datatable :deep(.vue3-easy-data-table__header .header) {
        border-bottom: 0;
    }

    .datatable :deep(.vue3-easy-data-table__body .action-column) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>