<!--suppress HtmlFormInputWithoutLabel, HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <div class="date-range-editor position-relative">
        <form @submit.prevent="save" v-if="editor">
            <p :class="getTextClasses" @click="edit = true" class="my-0 position-relative" v-if="!edit">
                {{ fieldIdentifierFrom }}: {{ fieldValueFrom }} {{ fieldIdentifierTo }}: {{ fieldValueTo }}
                <span :title="hint" class="edit-icon">
                    <CIcon name="cil-pencil" size="sm"/>
                </span>
            </p>
            <CInputGroup :class="getInputClasses" class="my-0" v-else>
                <CFormInput :type="type" :placeholder="hint" size="sm" v-model="fieldValueFrom"/>
                <CFormInput :type="type" :placeholder="hint" size="sm" v-model="fieldValueTo"/>
                <CButton @click="save" class="btn btn-outline-primary btn-save" type="button" size="sm"><CIcon name="cil-save" size="sm"/></CButton>
                <CButton @click="cancel" class="btn btn-outline-danger btn-cancel" type="button" size="sm"><CIcon name="cil-x" size="sm"/></CButton>
            </CInputGroup>
        </form>
        <p :class="getTextClasses" class="position-relative" v-else>
            {{ fieldIdentifierFrom }}: {{ fieldValueFrom }} {{ fieldIdentifierTo }}: {{ fieldValueTo }}
        </p>
        <div class="d-flex justify-content-center align-items-center loading-overlay" ref="editorOverlay">
            <CSpinner color="info" size="sm"/>
        </div>
    </div>
</template>

<script>
    import {axios} from './../mixins';

    export default {
        name: "DateRangeEditor",
        mixins: [axios],
        props: {
            valueFrom: {required: true},
            valueTo: {required: true},
            name: {required: true},
            endpoint: {required: true},
            identifierFrom: {required: true},
            identifierTo: {required: true},
            hint: {default: null},
            type: {default: 'date'},
            textClasses: [String, Object],
            inputClasses: [String, Object],
            editor: {type: Boolean, default: true}
        },
        data() {
            return {
                edit: false,
                fieldValueFrom: this.valueFrom,
                fieldValueTo: this.valueTo,
                fieldIdentifierFrom: this.identifierFrom,
                fieldIdentifierTo: this.identifierTo,
                loading: false
            };
        },
        computed: {
            getTextClasses() {
                return this.getClasses(this.textClasses);
            },
            getInputClasses() {
                return this.getClasses(this.inputClasses);
            }
        },
        watch: {
            valueFrom(newValue) {
                this.fieldValueFrom = newValue;
            },
            valueTo(newValue) {
                this.fieldValueTo = newValue;
            },
            identifierFrom(newValue) {
                this.fieldIdentifierFrom = newValue;
            },
            identifierTo(newValue) {
                this.fieldIdentifierTo = newValue;
            },
            loading(newValue) {
                this.$refs.editorOverlay.style.zIndex = newValue ? 1 : -1;
            }
        },
        methods: {
            async save() {
                document.activeElement.blur();
                this.loading = true;
                const {status, statusText, data: response} = await this._api().patch(this.endpoint, {
                    [this.fieldIdentifierFrom]: this.fieldValueFrom,
                    [this.fieldIdentifierTo]: this.fieldValueTo,
                });
                switch (status) {
                    case 200:
                        this.$emit('update', {name: this.name, data: response});
                        break;
                    case 422: {
                        let message = statusText;
                        if (response[this.fieldIdentifierFrom]) {
                            message = response[this.fieldIdentifierFrom][0];
                        } else if (response[this.fieldIdentifierTo]) {
                            message = response[this.fieldIdentifierTo][0];
                        }
                        this.fieldValueFrom = this.valueFrom;
                        this.fieldValueTo = this.valueTo;
                        this.$emit('error', {data: {title: status, message, type: 'danger'}});
                        break;
                    }
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.save();
                        } else {
                            this.$emit('error', {
                                type: 'refresh',
                                data: {title: status, message: statusText, type: 'danger'}
                            });
                        }
                        break;
                }
                this.cancel();
            },
            cancel() {
                this.fieldValue = this.value;
                this.edit = false;
                this.loading = false;
            },
            getClasses($classes) {
                let classes = [];
                switch (typeof $classes) {
                    case 'string':
                        classes.push($classes);
                        break;
                    case 'object':
                        Object.keys($classes).forEach(key => {
                            if ($classes[key]) {
                                classes.push(key);
                            }
                        });
                }
                return classes.join(' ');
            }
        }
    };
</script>

<style scoped>
    .date-range-editor {
        min-height: 21px;
    }

    .edit-icon {
        border: 1px solid;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: var(--cui-primary);
        cursor: pointer;
        z-index: 1;
    }

    .btn-save, .btn-cancel {
        /*height: 20px;*/
        /*width: 20px;*/
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0.7;
        z-index: -1;
        border: 3px solid transparent;
    }
</style>