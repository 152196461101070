<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <div class="d-flex justify-content-center">
        <figure class="m-0 position-relative">
            <img :src="profilePicture" alt="User profile picture" class="profile-picture img-fluid border-radius-50">
            <div v-if="editor">
                <figcaption @click="() => {$refs.profilePicture.click();}" class="profile-picture-caption">
                    <CIcon name="cil-pencil"/>
                </figcaption>
                <input @input="update" accept="image/*" class="d-none" ref="profilePicture" type="file"/>
                <div class="d-flex justify-content-center align-items-center border-radius-50 profile-picture-overlay" ref="profilePictureOverlay">
                    <CSpinner color="info" size="sm"/>
                </div>
            </div>
        </figure>
    </div>
</template>

<script>
    import {miscellaneous} from './../mixins';

    export default {
        name: "ProfilePicture",
        mixins: [miscellaneous],
        props: {
            src: {type: String, required: true},
            editor: {type: Boolean, default: true}
        },
        data() {
            return {
                profilePicture: this.src,
                loading: false
            };
        },
        watch: {
            src(newValue) {
                this.profilePicture = newValue;
            },
            loading(newValue) {
                this.$refs.profilePictureOverlay.style.opacity = newValue ? '0.7' : '0';
            }
        },
        methods: {
            async update(event) {
                if(event.target.files.length > 0) {
                    try {
                        this.$emit('update', {data: {file: await this.compressImage(event.target.files[0])}});
                    } catch (ex) {
                        this.$store.commit('addToast', {message: ex.toString(), type: 'warning'});
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .profile-picture-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0;
        border: 3px solid transparent;
    }

    .profile-picture {
        border: 3px solid var(--cui-light);
        border-radius: 50%;
        margin: 0 auto;
        width: 100px;
        height: 100px;
    }

    .profile-picture-caption {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: var(--cui-primary);
        box-shadow: 0 0 8px 3px #adb5bd;
        cursor: pointer;
        z-index: 1;
    }
</style>