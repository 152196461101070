<template>
    <div class="card-header-actions">
        <small>
            <slot></slot>
        </small>
    </div>
</template>

<script>
    export default {
        name: "CardHeaderAction"
    };
</script>

<style scoped>
    .card-header-actions {
        position: absolute;
        top: var(--cui-card-cap-padding-y);
        right: var(--cui-card-cap-padding-x);
    }
</style>