<template>
    <div class="modal-container confirm-modal-dialog">
        <div class="modal-body">
            <button type="button" class="btn btn-close" @click="$emit('cancel')"></button>
<!--            <span class="modal-close" @click="$emit('cancel')"><i class="icon-cross"></i></span>-->
            <h4>{{ question }}</h4>
            <div class="modal-action">
                <button class="modal-button confirm" @click="$emit('confirm')">Confirm</button>
                <button class="modal-button" @click="$emit('cancel')">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    // import { defineProps, defineEmits } from 'vue'

    // const props = defineProps(['question']);
    // const emit = defineEmits(['confirm', 'cancel']);

    export default {
        name: "ConfirmModal",
        emits: ["confirm", "cancel"],
        props: {
            question: null
        }
    };
</script>

<style scoped>
    .modal-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 21, 0.5);
        z-index: 1500;
    }
    .modal-body {
        background-color: #fff;
        border: 2px solid var(--cui-modal-border-color);
        border-radius: 10px;
        text-align: center;
        padding: 20px 40px;
        min-width: 250px;
        display: flex;
        flex-direction: column;
    }
    .modal-action {
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: center;
    }
    .modal-button {
        cursor: pointer;
        max-height: 50px;
        padding: 0 30px;
        border: 2px solid var(--cui-secondary);
        border-radius: 5px;
        background-color: var(--cui-secondary);
        color: #fff;
        font-weight: 600;
        font-size: 18px;
    }
    .modal-button.confirm {
        border-color: var(--cui-warning);
        background-color: var(--cui-warning);
    }
    .btn-close {
        cursor: pointer;
        position: relative;
        align-self: end;
        right: -33px;
        top: -17px;
    }
</style>