// rollbar.config.js
export default {
    accessToken: 'd0ce70d067674c608493a2d826258e4e',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: process.env.NODE_ENV.toLowerCase(),
        client: {
            javascript: {
                code_version: require('../package.json').version
            }
        }
    }
};