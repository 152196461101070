export default {
  "placeholders": {
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOB"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["icon"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "steam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steam"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "skype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skype"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "tumblr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tumblr"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin"])},
    "profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "error_500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 500"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "error_404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "portfolios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolios"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "social_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social link"])},
    "social_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Links"])},
    "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])},
    "click_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to edit"])},
    "click_to_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to open"])},
    "add_new_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new skill"])},
    "add_more_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more skill"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for was not found."])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
    "ops_you_are_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! You're lost."])},
    "username_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or Email"])},
    "no_data_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
    "page_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for is temporarily unavailable."])},
    "update_is_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update is available."])},
    "add_new_social_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new social link"])},
    "create_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "add_more_social_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more social link"])},
    "confirm_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this record?"])},
    "sign_in_to_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
    "what_are_you_looking_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you looking for?"])},
    "houston_we_have_a_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houston, we have a problem!"])}
  }
}