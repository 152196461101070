<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <CModal :visible="visible" @close="close" backdrop="static">
        <CModalHeader>
            <CModalTitle>{{ modalTitle }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CFormInput :feedback-invalid="form.errors.get('name')" :invalid="form.errors.exists('name')"
                        @input="form.errors.clear('name')" class="mb-2" label="Name" placeholder="Name" size="sm"
                        type="text" v-model="form.name"/>
            <CFormInput :feedback-invalid="form.errors.get('title')" :invalid="form.errors.exists('title')"
                        @input="form.errors.clear('title')" class="mb-2" label="Title" placeholder="Title" size="sm"
                        type="text" v-model="form.title"/>
            <CFormInput :feedback-invalid="form.errors.get('email')" :invalid="form.errors.exists('email')"
                        @input="form.errors.clear('email')" class="mb-2" label="Email" placeholder="Email" size="sm"
                        type="text" v-model="form.email"/>
            <CFormInput :feedback-invalid="form.errors.get('phone')" :invalid="form.errors.exists('phone')"
                        @input="form.errors.clear('phone')" class="mb-2" label="Phone" placeholder="Phone" size="sm"
                        type="text" v-model="form.phone"/>
            <CFormInput :feedback-invalid="form.errors.get('dob')" :invalid="form.errors.exists('dob')"
                        @input="form.errors.clear('dob')" class="mb-2" label="Date of birth" placeholder="Date of birth" size="sm"
                        type="date" v-model="form.dob"/>
            <CFormInput :feedback-invalid="form.errors.get('location')" :invalid="form.errors.exists('location')"
                        @input="form.errors.clear('location')" class="mb-2" label="Location" placeholder="Location" size="sm"
                        type="text" v-model="form.location"/>
            <label class="form-label">About</label>
            <ckeditor :config="textEditor.config" :editor="textEditor.type" @input="form.errors.clear('about')"
                      v-model="form.about"/>
        </CModalBody>
        <CModalFooter>
            <CButton @click="removePrompt" class="me-auto" color="danger" v-if="profile" variant="outline">
                {{ $t('placeholders.delete') }}
            </CButton>
            <CButton @click="close" color="secondary">{{ $t('placeholders.cancel') }}</CButton>
            <CButton :disabled="form.errors.any()" @click="save" color="primary">{{ $t('placeholders.save') }}</CButton>
        </CModalFooter>
        <div class="d-flex justify-content-center align-items-center loading-overlay" ref="modelOverlay">
            <CSpinner color="info" size="sm"/>
        </div>
    </CModal>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import {axios, miscellaneous} from './../../mixins';
    import {Errors} from './../../models';
    import useConfirmBeforeAction from "./../../utils/useConfirmBeforeAction";

    export default {
        name: "ProfileModal",
        mixins: [axios, miscellaneous],
        props: {
            endpoint: {type: String, required: true},
            show: {type: Boolean, required: true},
            title: {type: String, default: null},
            profile: {type: Object, default: null},
            closeOnBackdrop: {type: Boolean, default: false}
        },
        computed: {
            modalTitle() {
                return this.title ? this.title : this.$t('placeholders.profile');
            }
        },
        watch: {
            show(newValue) {
                this.visible = newValue;
            },
            profile(newValue) {
                this.resetForm();
                if (newValue) {
                    this.form.name = newValue.name;
                    this.form.title = newValue.title;
                    this.form.email = newValue.email;
                    this.form.phone = newValue.phone;
                    this.form.dob = newValue.dob;
                    this.form.location = newValue.location;
                    this.form.about = newValue.about;
                    this.form.profile_uuid = newValue.profile_uuid;
                }

            },
            loading(newValue) {
                this.$refs.modelOverlay.style.zIndex = newValue ? 1 : -1;
            }
        },
        data() {
            return {
                visible: this.show,
                loading: false,
                form: {
                    errors: new Errors(),
                    name: null,
                    title: null,
                    email: null,
                    phone: null,
                    dob: null,
                    location: null,
                    about: null,
                    profile_uuid: null
                },
                textEditor: {
                    type: ClassicEditor,
                    config: {
                        toolbar: ["heading", "|", "link", "bulletedList", "numberedList"]
                    },
                    tinyToolbar: ["heading", "|", "link", "bulletedList", "numberedList"],
                    fullToolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "imageUpload", "blockQuote", "insertTable", "mediaEmbed", "undo", "redo"]
                }
            };
        },
        methods: {
            removePrompt() {
                document.activeElement.blur();
                useConfirmBeforeAction(async () => {
                    await this.remove();
                }, {question: this.$t('placeholders.confirm_delete_message')});
            },
            async remove() {
                this.loading = true;
                const {status, statusText, data: response} = await this._api().delete(`${this.endpoint}/${this.profile.uuid}`);
                switch (status) {
                    case 200:
                        this.close({event: 'removed', data: response});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.remove();
                        } else {
                            this.$router.push({name: 'Logout', params: {locale: this.$i18n.locale}});
                        }
                        break;
                    case 404:
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
                this.loading = false;
            },
            async save() {
                document.activeElement.blur();
                this.loading = true;
                let apiResponse = {};
                if (this.profile) {
                    apiResponse = await this._api().put(`${this.endpoint}/${this.profile.uuid}`, this.form);
                } else {
                    apiResponse = await this._api().post(this.endpoint, this.form);
                }
                const {status, statusText, data: response} = apiResponse;
                switch (status) {
                    case 200:
                        this.close({event: this.profile ? 'updated' : 'created', data: response});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.save();
                        } else {
                            this.$router.push({name: 'Logout', params: {locale: this.$i18n.locale}});
                        }
                        break;
                    case 422:
                        this.form.errors.record(response);
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
                this.loading = false;
            },
            close(obj) {
                let event = null;
                let data = null;
                if (obj) {
                    event = obj.event ? obj.event : null;
                    data = obj.data ? obj.data : null;
                }
                this.$emit('closed', {name: this.$options.name, event, data});
                this.resetForm();
            },
            resetForm() {
                this.form.name = null;
                this.form.title = null;
                this.form.email = null;
                this.form.phone = null;
                this.form.dob = null;
                this.form.location = null;
                this.form.about = null;
                this.form.profile_uuid = null;
                this.form.errors.clearAll();
            }
        }
    };
</script>

<style scoped>
    :deep(.ck-editor) {
        min-height: 120px;
        max-height: 360px;
        overflow: scroll;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0.7;
        z-index: -1;
        border: 3px solid transparent;
    }
</style>