<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <CModal :visible="visible" @close="close" backdrop="static">
        <CModalHeader>
            <CModalTitle>{{ modalTitle }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CFormSelect :feedback-invalid="form.errors.get('profile_uuid')" :invalid="form.errors.exists('profile_uuid')"
                         :options="[{label: 'Select profile'}, ...$store.state.profiles]" @input="form.errors.clear('profile_uuid')"
                         class="mb-2" label="Profile" size="sm" v-model="form.profile_uuid"/>
            <CFormInput :feedback-invalid="form.errors.get('title')" :invalid="form.errors.exists('title')"
                        @input="form.errors.clear('title')" class="mb-2" label="Title" placeholder="Title" size="sm"
                        type="text" v-model="form.title"/>
            <CFormInput :feedback-invalid="form.errors.get('url')" :invalid="form.errors.exists('url')"
                        @input="form.errors.clear('url')" class="mb-2" label="URL" placeholder="URL"
                        size="sm" type="text" v-model="form.url"/>
        </CModalBody>
        <CModalFooter>
            <CButton @click="removePrompt" class="me-auto" color="danger" v-if="portfolio" variant="outline">
                {{ $t('placeholders.delete') }}
            </CButton>
            <CButton @click="close" color="secondary">{{ $t('placeholders.cancel') }}</CButton>
            <CButton :disabled="form.errors.any()" @click="save" color="primary">{{ $t('placeholders.save') }}</CButton>
        </CModalFooter>
        <div class="d-flex justify-content-center align-items-center loading-overlay" ref="modelOverlay">
            <CSpinner color="info" size="sm"/>
        </div>
    </CModal>
</template>

<script>
    import {axios, miscellaneous} from './../../mixins';
    import {Errors} from './../../models';
    import useConfirmBeforeAction from "./../../utils/useConfirmBeforeAction";

    export default {
        name: "PortfolioModal",
        mixins: [axios, miscellaneous],
        props: {
            endpoint: {type: String, required: true},
            show: {type: Boolean, required: true},
            title: {type: String, default: null},
            portfolio: {type: Object, default: null},
            closeOnBackdrop: {type: Boolean, default: false}
        },
        computed: {
            modalTitle() {
                return this.title ? this.title : this.$t('placeholders.portfolio');
            }
        },
        watch: {
            show(newValue) {
                this.visible = newValue;
            },
            portfolio(newValue) {
                this.resetForm();
                if (newValue) {
                    this.form.title = newValue.title;
                    this.form.url = newValue.url;
                    this.form.profile_uuid = newValue.profile_uuid;
                }

            },
            loading(newValue) {
                this.$refs.modelOverlay.style.zIndex = newValue ? 1 : -1;
            }
        },
        data() {
            return {
                visible: this.show,
                loading: false,
                form: {
                    errors: new Errors(),
                    title: null,
                    url: null,
                    profile_uuid: null
                }
            };
        },
        methods: {
            removePrompt() {
                document.activeElement.blur();
                useConfirmBeforeAction(async () => {
                    await this.remove();
                }, {question: this.$t('placeholders.confirm_delete_message')});
            },
            async remove() {
                this.loading = true;
                const {status, statusText, data: response} = await this._api().delete(`${this.endpoint}/${this.portfolio.uuid}`);
                switch (status) {
                    case 200:
                        this.close({event: 'removed', data: response});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.remove();
                        } else {
                            this.$router.push({name: 'Logout', params: {locale: this.$i18n.locale}});
                        }
                        break;
                    case 404:
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
                this.loading = false;
            },
            async save() {
                document.activeElement.blur();
                this.loading = true;
                let apiResponse = {};
                if (this.portfolio) {
                    apiResponse = await this._api().put(`${this.endpoint}/${this.portfolio.uuid}`, this.form);
                } else {
                    apiResponse = await this._api().post(this.endpoint, this.form);
                }
                const {status, statusText, data: response} = apiResponse;
                switch (status) {
                    case 200:
                        this.close({event: this.portfolio ? 'updated' : 'created', data: response});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.save();
                        } else {
                            this.$router.push({name: 'Logout', params: {locale: this.$i18n.locale}});
                        }
                        break;
                    case 422:
                        this.form.errors.record(response);
                        this.$store.commit('addToast', {title: status, message: statusText, type: 'danger'});
                        break;
                }
                this.loading = false;
            },
            close(obj) {
                let event = null;
                let data = null;
                if (obj) {
                    event = obj.event ? obj.event : null;
                    data = obj.data ? obj.data : null;
                }
                this.$emit('closed', {name: this.$options.name, event, data});
                this.resetForm();
            },
            resetForm() {
                this.form.title = null;
                this.form.url = null;
                this.form.profile_uuid = null;
                this.form.errors.clearAll();
            }
        }
    };
</script>

<style scoped>
    :deep(.ck-editor) {
        min-height: 120px;
        max-height: 360px;
        overflow: scroll;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0.7;
        z-index: -1;
        border: 3px solid transparent;
    }
</style>