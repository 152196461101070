<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <router-view></router-view>
    <snackbar :class="{'show' : updateExists}">
        <span style="margin-right: 1rem;">{{ $t('placeholders.update_is_available') }}</span>
        <CButton @click="refreshApp" color="light" shape="rounded-pill">{{ $t('placeholders.refresh') }}</CButton>
    </snackbar>
    <CToaster placement="top-end">
        <CToast :color="toast.type ? toast.type : 'primary'" :key="toast.id" @close="toastClosing(toast)"
                class="align-items-center" v-for="toast in $store.state.toasts">
            <div class="d-flex">
                <CToastHeader :class="'text-white bg-' + (toast.type ? toast.type : 'primary')" v-if="toast.title">
                    {{ toast.title }}
                </CToastHeader>
                <CToastBody class="text-white" v-if="toast.message">{{ toast.message }}</CToastBody>
                <CToastClose class="me-2 m-auto"/>
            </div>
        </CToast>
    </CToaster>
    <DialogsWrapper />
</template>

<script>
    import {update} from './mixins';

    export default {
        name: 'App',
        mixins: [update],
        beforeCreate() {
            this.$store.dispatch('loadStore');
        },
        methods: {
            toastClosing(toast) {
                this.$store.commit('removeToast', {id: toast.id});
            }
        }
    };
</script>

<style lang="scss">
    // Import Main styles for this application
    @import 'styles/style';
</style>
