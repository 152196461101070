<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <figure class="position-relative" style="max-height: 175px; overflow: hidden;">
        <img :alt="coverPhoto" :src="coverPhoto" class="h-100 w-100" style="max-height:175px; object-fit: cover; object-position: center;"/>
        <div v-if="editor">
            <figcaption @click="() => {$refs.coverPhoto.click();}" class="cover-photo-caption">
                <CIcon name="cil-pencil"/>
            </figcaption>
            <input @input="update" accept="image/*" class="d-none" ref="coverPhoto" type="file"/>
            <div class="d-flex justify-content-center align-items-center cover-photo-overlay" ref="coverPhotoOverlay">
                <CSpinner color="info" size="sm"/>
            </div>
        </div>
    </figure>
</template>

<script>
    import {miscellaneous} from './../mixins';

    export default {
        name: "CoverPhoto",
        mixins: [miscellaneous],
        props: {
            src: {type: String, required: true},
            editor: {type: Boolean, default: true}
        },
        data() {
            return {
                coverPhoto: this.src,
                loading: false
            };
        },
        watch: {
            src(newValue) {
                this.coverPhoto = newValue;
            },
            loading(newValue) {
                this.$refs.coverPhotoOverlay.style.opacity = newValue ? '0.7' : '0';
            }
        },
        methods: {
            async update(event) {
                if(event.target.files.length > 0) {
                    try {
                        this.$emit('update', {data: {file: await this.compressImage(event.target.files[0])}});
                    } catch (ex) {
                        this.$store.commit('addToast', {message: ex.toString(), type: 'warning'});
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .cover-photo-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0;
        border: 3px solid transparent;
    }

    .cover-photo-caption {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: var(--cui-primary);
        box-shadow: 0 0 8px 3px #adb5bd;
        cursor: pointer;
        z-index: 1;
    }
</style>