<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <div class="backdrop">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Backdrop"
    };
</script>

<style scoped>
    .backdrop {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, .55);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }
</style>