import {h, resolveComponent} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import i18n from "../i18n";
import store from "../store";

const {t} = i18n.global;

const router = createRouter({
    history: createWebHistory('/'),
    routes: configRoutes(),
    scrollBehavior() {
        return {top: 0};
    },
});

router.beforeEach((to) => {
    let locale = to.params.locale;
    if (!locale)
        locale = localStorage.getItem('locale');
    if (i18n.global.availableLocales.includes(locale)) {
        if (!locale) {
            let localLocale = localStorage.getItem('locale');
            locale = localLocale ? localLocale : locale;
        }
        localStorage.setItem('locale', locale);
        i18n.locale = locale;
    } else {
        locale = i18n.global.fallbackLocale.value;
        localStorage.setItem('locale', locale);
        i18n.locale = locale;
        router.push({name: to.name, params: {locale: locale}});
    }
});

export default router;

function configRoutes() {
    return [
        {
            path: '/',
            name: 'Index',
            beforeEnter: () => {
                let locale = localStorage.getItem('locale');
                router.push({name: 'Dashboard', params: {locale: locale}});
            }
        },
        {
            path: '/:locale',
            name: 'Locale',
            component: {
                beforeCreate() {
                    this.$i18n.locale = this.$router.currentRoute.value.params.locale;
                },
                render() {
                    return h(resolveComponent('router-view'));
                }
            },
            children: [
                {
                    path: '',
                    name: 'DefaultLayout',
                    component: () => import('./../layouts/DefaultLayout'),
                    beforeEnter: (from) => {
                        if (!store.state.auth.access_token) {
                            const query = {};
                            if(from.name !== 'Dashboard')
                                query.to = from.fullPath;
                            router.push({name: 'Login', params: {locale: i18n.locale}, query});
                            return false;
                        }
                    },
                    children: [
                        {
                            path: 'dashboard',
                            name: 'Dashboard',
                            meta: {label: t('placeholders.dashboard')},
                            component: () => import('./../views/Dashboard')
                        },
                        {
                            path: 'account',
                            name: 'Account',
                            meta: {label: t('placeholders.account')},
                            component: () => import('./../views/Account')
                        },
                        {
                            path: 'settings',
                            name: 'Settings',
                            meta: {label: t('placeholders.settings')},
                            component: () => import('./../views/Settings')
                        },
                        {
                            path: 'profiles',
                            meta: {label: t('placeholders.profiles')},
                            component: {
                                render() {
                                    return h(resolveComponent('router-view'));
                                }
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'Profiles',
                                    component: () => import('../views/profiles/List')
                                },
                                {
                                    path: ':uuid',
                                    name: 'ProfilesView',
                                    meta: {label: ':uuid'},
                                    component: () => import('../views/profiles/View')
                                },
                            ]
                        },
                        {
                            path: 'portfolios',
                            name: 'Portfolios',
                            meta: {label: t('placeholders.portfolios')},
                            component: () => import('./../views/Portfolios')
                        },
                        {
                            path: 'services',
                            name: 'Services',
                            meta: {label: t('placeholders.services')},
                            component: () => import('./../views/Services')
                        },
                        {
                            path: 'experiences',
                            name: 'Experiences',
                            meta: {label: t('placeholders.experiences')},
                            component: () => import('./../views/Experiences')
                        },
                        {
                            path: 'education',
                            name: 'Education',
                            meta: {label: t('placeholders.education')},
                            component: () => import('./../views/Education')
                        },
                        {
                            path: 'testimonials',
                            name: 'Testimonials',
                            meta: {label: t('placeholders.testimonials')},
                            component: () => import('./../views/Testimonials')
                        },
                        {
                            path: 'users',
                            name: 'Users',
                            meta: {label: t('placeholders.users')},
                            component: () => import('./../views/Users')
                        },
                        {
                            path: 'contact-us',
                            name: 'ContactUs',
                            meta: {label: t('placeholders.contact_us')},
                            component: () => import('./../views/ContactUs')
                        }
                    ],
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: () => import('./../views/pages/Login'),
                    beforeEnter: () => {
                        if (store.state.auth.access_token) {
                            router.push({name: 'Dashboard', params: {locale: i18n.locale}});
                            return false;
                        }
                    }
                },
                {
                    path: 'logout',
                    name: 'Logout',
                    component: {
                        render() {
                            // this.$store.commit('resetAuthTokens');
                            this.$store.dispatch('resetStore');
                            this.$router.push({name: 'Login', params: {locale: this.$i18n.locale}});
                        }
                    }
                },
                {
                    path: '404',
                    name: 'Page404',
                    component: () => import('./../views/pages/Page404')
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: () => import('./../views/pages/Page500')
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: {
                render() {
                    this.$router.push({name: 'Page404', params: {locale: this.$i18n.locale}});
                }
            }
        }
    ];
}
