<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <div class="image-editor">
        <CImage :fluid="fluid" :rounded="rounded" :src="fieldSrc" :thumbnail="thumbnail"/>
        <div @click="$refs.image.click()" class="image-action">
            <CIcon name="cil-pencil"/>
            <input @input="update" accept="image/*" class="d-none" ref="image" type="file"/>
        </div>
        <div class="d-flex justify-content-center align-items-center loading-overlay" ref="editorOverlay">
            <CSpinner color="info" size="sm"/>
        </div>
    </div>
</template>

<script>
    import {axios, miscellaneous} from './../mixins';

    export default {
        name: "ImageEditor",
        emits: ["update", "error"],
        mixins: [axios, miscellaneous],
        props: {
            src: {required: true},
            name: {required: true},
            endpoint: {required: true},
            fluid: {default: true},
            rounded: {default: false},
            thumbnail: {default: false},
            identifier: {required: true},
        },
        data() {
            return {
                fieldSrc: this.src,
                fieldIdentifier: this.identifier,
                loading: false
            };
        },
        watch: {
            src(newValue) {
                this.fieldSrc = newValue;
            },
            identifier(newValue) {
                this.fieldIdentifier = newValue;
            },
            loading(newValue) {
                this.$refs.editorOverlay.style.zIndex = newValue ? 1 : -1;
            }
        },
        methods: {
            async update(event) {
                document.activeElement.blur();
                if (event.target.files.length > 0) {
                    try {
                        this.loading = true;
                        let formData = new FormData();
                        formData.append(this.fieldIdentifier, await this.compressImage(event.target.files[0]));
                        const {status, statusText, data: response} = await this._api().post(this.endpoint, formData);
                        switch (status) {
                            case 200:
                                this.$emit('update', {name: this.name, data: response});
                                break;
                            case 422:
                                this.$emit('error', {data: {title: status, message: response[this.fieldIdentifier] ? response[this.fieldIdentifier][0] : statusText, type: 'danger'}});
                                break;
                            case 401:
                                if (await this.refreshAccessToken()) {
                                    return await this.update(event);
                                } else {
                                    this.$emit('error', {type: 'refresh', data: {title: status, message: statusText, type: 'danger'}});
                                }
                                break;
                        }
                        this.loading = false;
                    } catch (ex) {
                        this.$store.commit('addToast', {message: ex.toString(), type: 'warning'});
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .image-editor {
        position: relative;
    }

    .image-action {
        position: absolute;
        top: var(--cui-card-img-overlay-padding);
        right: var(--cui-card-img-overlay-padding);
        border: 1px solid;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: var(--cui-primary);
        box-shadow: 0 0 8px 3px #adb5bd;
        cursor: pointer;
        z-index: 1;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0.7;
        z-index: -1;
        border: 3px solid transparent;
    }
</style>