export default {
    state: {
        access_token: null,
        refresh_token: null,
        type: null
    },
    mutations: {
        updateAuthTokens(state, payload) {
            if(payload.access_token) {
                state.access_token = payload.access_token;
            }
            if(payload.refresh_token) {
                state.refresh_token = payload.refresh_token;
            }
            if(payload.type) {
                state.type = payload.type;
            }
            localStorage.setItem('auth', JSON.stringify(state));
        },
        loadAuthToken(state) {
            let auth = null;
            if (localStorage.getItem('auth')) {
                auth = localStorage.getItem('auth');
            }
            auth = JSON.parse(auth);
            if(auth) {
                state.access_token = auth.access_token;
                state.refresh_token = auth.refresh_token;
                state.type = auth.type;
            }
        },
        resetAuthTokens(state) {
            localStorage.removeItem('auth');
            state.access_token = null;
            state.refresh_token = null;
            state.type = null;
        }
    }
};