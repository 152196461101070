import {createApp} from 'vue';
import CoreuiVue from '@coreui/vue';
import App from './App';
import RollbarPlugin from './rollbar';
import router from './router';
import store from './store';
import CIcon from '@coreui/icons-vue';
import {iconsSet as icons} from './assets/icons';
import CKEditor from '@ckeditor/ckeditor5-vue';
import * as ConfirmDialog from 'vuejs-confirm-dialog';
// import VueCal from 'vue-cal';
import {
    Backdrop,
    CardHeaderAction,
    CoverPhoto,
    Datatable,
    LocaleSwitcher,
    ProfilePicture,
    Snackbar,
    TextAreaEditor,
    TextEditor,
    RichTextEditor,
    DateEditor,
    DateRangeEditor,
    ImageEditor
} from './components';
import './registerServiceWorker';
import i18n from './i18n';

const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
    console.log(info, err.toString());
};
app.use(RollbarPlugin);
app.use(router);
app.use(store);
app.use(CoreuiVue);
app.use(i18n);
app.use(CKEditor);
app.use(ConfirmDialog);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('snackbar', Snackbar);
app.component('locale-switcher', LocaleSwitcher);
app.component('backdrop', Backdrop);
app.component('datatable', Datatable);
app.component('card-header-action', CardHeaderAction);
app.component('cover-photo', CoverPhoto);
app.component('profile-picture', ProfilePicture);
app.component('text-editor', TextEditor);
app.component('textarea-editor', TextAreaEditor);
app.component('rich-text-editor', RichTextEditor);
app.component('date-editor', DateEditor);
app.component('date-range-editor', DateRangeEditor);
app.component('image-editor', ImageEditor);
// app.component('vue-cal', VueCal);

app.mount('#app');
