import Compressor from 'compressorjs';
import moment from 'moment';
import store from './../store';

export default {
    data() {
        return {
            recommended_social_platforms: ['facebook', 'linkedin', 'tumblr', 'whatsapp', 'youtube', 'instagram', 'twitter', 'discord', 'skype', 'steam']
        };
    },
    methods: {
        strLimit(string, length = 100) {
            if (string && string.length > length)
                return string.substring(0, length) + '...';
            return string;
        },
        removeEmptyAttributes(obj) {
            for (let prop in obj) {
                if (obj[prop] === '' || obj[prop] === null || obj[prop] === undefined) {
                    delete obj[prop];
                }
            }
            return obj;
        },
        trimAllAttributes(obj) {
            for (let prop in obj) {
                if (typeof obj[prop] === 'string') {
                    obj[prop] = obj[prop].trim();
                }
            }
            return obj;
        },
        getFormDataFromObject(object) {
            const formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        prependValueToArray(value, array) {
            const newArray = array.slice();
            newArray.unshift(value);
            return newArray;
        },
        getFullStoragePath(path) {
            return `${store.state.endpoints.storage}/${path}`;
        },
        getMoment(datetime) {
            return moment(datetime);
        },
        compressImage(file, quality = 0.6, maxWidth = 1920, maxHeight = 1080) {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: quality,
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                    success(file) {
                        resolve(file);
                    }, error(err) {
                        reject(err);
                    }
                });
            });
        }
    }
};