import {createStore} from 'vuex';
import auth from './auth';
import endpoints from './endpoints';
import {uniqueId} from "lodash";

export default createStore({
    state: {
        sidebarVisible: '',
        sidebarUnfoldable: false,
        toasts: [],
        profiles: [],
        reloadCount: 0,
        api: {
            key: process.env.VUE_APP_API_KEY
        }
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible;
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable;
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value;
        },
        setProfiles(state, profiles) {
            state.profiles = profiles.map(profile => {
                return {value: profile.uuid, label: `${profile.name} (${profile.title})`};
            });
        },
        addToast(state, payload) {
            state.toasts.push({
                id: uniqueId('toast_'),
                title: payload.title,
                message: payload.message,
                type: payload.type
            });
        },
        removeToast(state, payload) {
            state.toasts = state.toasts.filter(toast => toast.id !== payload.id);
        },
        increaseReloadCount(state) {
            state.reloadCount += 1;
        }
    },
    actions: {
        loadStore(context) {
            context.commit('loadAuthToken');
        },
        resetStore(context) {
            context.commit('resetAuthTokens');
        }
    },
    modules: {
        auth,
        endpoints
    }
});
