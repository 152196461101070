import axios from 'axios';

export default {
    data() {
        return {
            api: {
                key: this.$store.state.api.key,
                endpoint: this.$store.state.endpoints.api
            }
        };
    },
    methods: {
        _api() {
            const headers = {};
            switch (this.$store.state.auth.type) {
                case 'Bearer':
                    headers.Authorization = `Bearer ${this.$store.state.auth.access_token}`;
                    break;
                case 'api':
                    headers.Authorization = this.$store.state.auth.access_token;
                    break;
            }
            const instance = axios.create({
                baseURL: this.api.endpoint,
                headers: {
                    'x-api-key': this.api.key,
                    locale: this.$i18n.locale,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...headers
                }
            });
            instance.interceptors.response.use(response => response, error => {
                return error.response;
            });
            return instance;
        },
        async refreshAccessToken() {
            const response = await this._api().post('refresh', {refresh_token: this.$store.state.auth.refresh_token});
            this.$store.commit('updateAuthTokens', response.data);
            if (response.status === 200) {
                return true;
            }
            this.$store.commit('addToast', {title: response.status, message: response.data.message ? response.data.message : response.statusText, type: 'danger'});
            return false;
        }
    }
};