<!--suppress HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <div class="locale-switcher">
        <label class="d-none" for="locale_switcher"></label>
        <CIcon class="mx-2" icon="cil-globe-alt" size="lg"/>
        <select @input="localeSwitch" id="locale_switcher" v-model="$i18n.locale">
            <option :key="`Lang${i}`" :value="lang" v-for="(lang, i) in $i18n.availableLocales">{{ lang }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "LocaleSwitcher",
        methods: {
            localeSwitch(event) {
                this.$router.push({params: {locale: event.target.value}});
            }
        }
    };
</script>

<style scoped>
    .locale-switcher {
    }
</style>