<!--suppress HtmlFormInputWithoutLabel, HtmlUnknownTag, CheckEmptyScriptTag -->
<template>
    <div class="text-editor position-relative">
        <form @submit.prevent="save" v-if="editor">
            <p :class="getTextClasses" @click="edit = true" class="my-0 position-relative" v-if="!edit">
                {{ fieldValue }}
                <span :title="hint" class="edit-icon">
                    <CIcon name="cil-pencil" size="sm"/>
                </span>
            </p>
            <CInputGroup :class="getInputClasses" class="my-0" v-else>
                <CFormInput :type="type" :placeholder="hint" size="sm" v-model="fieldValue"/>
                <CButton @click="save" class="btn btn-outline-primary btn-save" type="button" size="sm"><CIcon name="cil-save" size="sm"/></CButton>
                <CButton @click="cancel" class="btn btn-outline-danger btn-cancel" type="button" size="sm"><CIcon name="cil-x" size="sm"/></CButton>
            </CInputGroup>
        </form>
        <p :class="getTextClasses" class="position-relative" v-else>{{ fieldValue }}</p>
        <div class="d-flex justify-content-center align-items-center loading-overlay" ref="editorOverlay">
            <CSpinner color="info" size="sm"/>
        </div>
    </div>
</template>

<script>
    import {axios} from './../mixins';

    export default {
        name: "TextEditor",
        mixins: [axios],
        props: {
            value: {required: true},
            name: {required: true},
            endpoint: {required: true},
            identifier: {required: true},
            hint: {default: null},
            type: {default: 'text'},
            textClasses: [String, Object],
            inputClasses: [String, Object],
            editor: {type: Boolean, default: true}
        },
        data() {
            return {
                edit: false,
                fieldValue: this.value,
                fieldIdentifier: this.identifier,
                loading: false
            };
        },
        computed: {
            getTextClasses() {
                return this.getClasses(this.textClasses);
            },
            getInputClasses() {
                return this.getClasses(this.inputClasses);
            }
        },
        watch: {
            value(newValue) {
                this.fieldValue = newValue;
            },
            identifier(newValue) {
                this.fieldIdentifier = newValue;
            },
            loading(newValue) {
                this.$refs.editorOverlay.style.zIndex = newValue ? 1 : -1;
            }
        },
        methods: {
            async save() {
                document.activeElement.blur();
                this.loading = true;
                const {status, statusText, data: response} = await this._api().patch(this.endpoint, {
                    [this.fieldIdentifier]: this.fieldValue
                });
                switch (status) {
                    case 200:
                        this.$emit('update', {name: this.name, data: response});
                        break;
                    case 422:
                        this.$emit('error', {data: {title: status, message: response[this.fieldIdentifier] ? response[this.fieldIdentifier][0] : statusText, type: 'danger'}});
                        break;
                    case 401:
                        if (await this.refreshAccessToken()) {
                            return await this.save();
                        } else {
                            this.$emit('error', {type: 'refresh', data: {title: status, message: statusText, type: 'danger'}});
                        }
                        break;
                }
                this.cancel();
            },
            cancel() {
                this.fieldValue = this.value;
                this.edit = false;
                this.loading = false;
            },
            getClasses($classes) {
                let classes = [];
                switch (typeof $classes) {
                    case 'string':
                        classes.push($classes);
                        break;
                    case 'object':
                        Object.keys($classes).forEach(key => {
                            if ($classes[key]) {
                                classes.push(key);
                            }
                        });
                }
                return classes.join(' ');
            }
        }
    };
</script>

<style scoped>
    .title {
        font-size: 21px;
        margin-top: 5px;
        min-height: 21px;
    }

    .text-editor {
        min-height: 21px;
    }

    .edit-icon {
        border: 1px solid;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: var(--cui-primary);
        cursor: pointer;
        z-index: 1;
    }

    .btn-save, .btn-cancel {
        /*height: 20px;*/
        /*width: 20px;*/
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 3px;
        background-color: white;
        opacity: 0.7;
        z-index: -1;
        border: 3px solid transparent;
    }
</style>