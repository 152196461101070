import { createConfirmDialog } from "vuejs-confirm-dialog";
import {ConfirmModal} from "./../views/modals";

const useConfirmBeforeAction = (action, props) => {
    const { reveal, onConfirm } = createConfirmDialog(ConfirmModal, props);

    onConfirm(action);

    reveal();
};

export default useConfirmBeforeAction;
