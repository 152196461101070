import {
    cibDiscord,
    cibFacebook,
    cibInstagram,
    cibLinkedin,
    cibSkype,
    cibSteam,
    cibTumblr,
    cibTwitter,
    cibWhatsapp,
    cibYoutube,
    cilAt,
    cilBirthdayCake,
    cilChevronBottom,
    cilChevronTop,
    cilCode,
    cilCommentBubble,
    cilEducation,
    cilFolderOpen,
    cilGroup,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMenu,
    cilNotes,
    cilPencil,
    cilPhone,
    cilPlus,
    cilReload,
    cilSave,
    cilSettings,
    cilSpeedometer,
    cilTask,
    cilTrash,
    cilUser,
    cilX,
} from '@coreui/icons';

export const iconsSet = Object.assign(
    {},
    {
        cibDiscord,
        cibFacebook,
        cibInstagram,
        cibLinkedin,
        cibSkype,
        cibSteam,
        cibTumblr,
        cibTwitter,
        cibWhatsapp,
        cibYoutube,
        cilAt,
        cilBirthdayCake,
        cilChevronBottom,
        cilChevronTop,
        cilCode,
        cilCommentBubble,
        cilEducation,
        cilFolderOpen,
        cilGroup,
        cilList,
        cilLocationPin,
        cilLockLocked,
        cilMenu,
        cilNotes,
        cilPencil,
        cilPhone,
        cilPlus,
        cilReload,
        cilSave,
        cilSettings,
        cilSpeedometer,
        cilTask,
        cilTrash,
        cilUser,
        cilX
    }
);
